export const wealthMountainGraphData = [
  { x: 0, y: 0 },
  { x: 0.01031, y: 11014 },
  { x: 0.0136, y: 26747 },
  { x: 0.01795, y: 42852 },
  { x: 0.02368, y: 61559 },
  { x: 0.03125, y: 84241 },
  { x: 0.04123, y: 114968 },
  { x: 0.05441, y: 164218 },
  { x: 0.07179, y: 254196 },
  { x: 0.09473, y: 431583 },
  { x: 0.125, y: 784429 },
  { x: 0.16494, y: 1469111 },
  { x: 0.21764, y: 2652502 },
  { x: 0.28717, y: 4610056 },
  { x: 0.37893, y: 8336160 },
  { x: 0.5, y: 15727837 },
  { x: 0.65975, y: 28727022 },
  { x: 0.87055, y: 52174974 },
  { x: 1.1487, y: 91747453 },
  { x: 1.51572, y: 156287967 },
  { x: 2, y: 262440200 },
  { x: 2.63902, y: 409579777 },
  { x: 3.4822, y: 589616825 },
  { x: 4.59479, y: 745958022 },
  { x: 6.06287, y: 816928185 },
  { x: 8, y: 801400751 },
  { x: 10.55606, y: 752616627 },
  { x: 13.92881, y: 664405974 },
  { x: 18.37917, y: 565691692 },
  { x: 24.25147, y: 458829842 },
  { x: 32, y: 372970756 },
  { x: 42.22425, y: 331239587 },
  { x: 55.71524, y: 294024005 },
  { x: 73.51669, y: 240065510 },
  { x: 97.00586, y: 173794948 },
  { x: 128, y: 109969124 },
  { x: 168.89701, y: 63847976 },
  { x: 222.86094, y: 33851922 },
  { x: 294.06678, y: 17131932 },
  { x: 388.02344, y: 8354858 },
  { x: 512, y: 3921168 },
  { x: 675.58805, y: 1835729 },
  { x: 891.44378, y: 1051263 },
  { x: 1176.26712, y: 710308 },
  { x: 1552.09376, y: 481437 },
  { x: 2048, y: 322692 },
  { x: 2702.3522, y: 214598 },
  { x: 3565.77511, y: 142162 },
  { x: 4705.06846, y: 94952 },
  { x: 6208.37506, y: 64488 },
  { x: 8192, y: 44553 },
];
